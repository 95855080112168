import React from "react"
import { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Aurora from "../components/Aurora/Aurora"
import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"
import BackToTopButton from "../components/Buttons/BackToTopButton"
import useSound from 'use-sound';

export default function Layout({ children }) {
  const barks = [
    '/mp3/dog-01.mp3',
    '/mp3/dog-02.mp3',
    '/mp3/dog-03.mp3',
    '/mp3/dog-04.mp3',
    '/mp3/dog-05.mp3',
    '/mp3/dog-06.mp3',
    '/mp3/dog-07.mp3',
    '/mp3/dog-08.mp3',
    '/mp3/dog-09.mp3',
  ]

  const getRandomBark = ()=> {
    let random = Math.floor(Math.random() * barks.length);
    return random
  }

  const [play0] = useSound(barks[0], { volume: 0.5});
  const [play1] = useSound(barks[1], { volume: 0.5});
  const [play2] = useSound(barks[2], { volume: 0.5});
  const [play3] = useSound(barks[3], { volume: 0.5});
  const [play4] = useSound(barks[4], { volume: 0.5});
  const [play5] = useSound(barks[5], { volume: 0.5});
  const [play6] = useSound(barks[6], { volume: 0.5});
  const [play7] = useSound(barks[7], { volume: 0.5});
  const [play8] = useSound(barks[8], { volume: 0.5});

  const bark = ()=> {
    let random = getRandomBark()
    switch(random){
      case 0:
        play5();
        break;
      case 1:
        play1();
        break;
      case 2:
        play2();
        break;
      case 3:
        play3();
        break;
      case 4:
        play4();
        break;
      case 5:
        play5();
        break;
      case 6:
        play6();
        break;
      case 7:
        play7();
        break;
      case 8:
        play8();
        break;
      default:
        play5();
        // code block
        }
  }

  const pointer = useRef(null);
  const [mousePos, setMousePos] = useState({ x:-30, y:-30 });
  const isBrowser = () => typeof window !== "undefined"
  let path = ()=> {
    if(typeof window !== "undefined"){
      return window.location.pathname
    } else {
      return null
    }
    ;
  }

  const [windowPath, setWindowPath] = useState(path);

  
  /* potentially changes navbar on page change */
  useEffect(() => {
    setWindowPath(window?.location.pathname)
  }, [path]);

  // React.useEffect(() => {
  //   if(window){
  //     window.addEventListener("locationchange", ()=>{
  //       alert('blah')
  //       setWindowPath(window?.location.pathname)
  //     });
      
  //   }
  // }, [isBrowser]);


  // useEffect(() => {
  //     // gsap.to(iconElement.current, 0, { x:(mousePos['x'] / window.outerWidth) * (window.outerWidth/-6) , y:(mousePos['y'] / window.outerWidth) * (window.outerWidth/-6), delay:.05 })
  // }, [mousePos]);

  const mouseEnter = ()=> {
      // document.addEventListener('mousemove', function(e){
      //     setTimeout(()=>{
      //       logMouseMove(e)
      //     }, 1000)
          
      // })
  }

  const mouseLeave = ()=> {
      document.removeEventListener('mousemove', function(e){
          setMousePos({ 
              x: -30, 
              y: -30
          })
      })
  }

  const logMouseMove = (e)=>{
      setMousePos({ 
        x: e.clientX, 
        y: e.clientY
     })
  }

  useEffect(() => {
    document.body.classList.add('no-cursor')
    document.addEventListener('mousemove', function(e){
      setTimeout(()=>{
        logMouseMove(e)
      }, 100)
    })
  },[])

  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />

        <meta property="og:title" content="Barkhaus" />
        <meta property="og:description" content="Barkhaus is a barketing and communications agency, moving at the speed of barkchain and Woof3."></meta>
        <meta property="og:url" content="https://barkhaus.io/" />
        <meta property="og:image" content="https://Barkhaus.io/favicon/metaimage_barkhaus.png" />

        <meta name="twitter:title" content="Barkhaus" />
        <meta name="twitter:description" content="Barkhaus is a barketing and communications agency, moving at the speed of barkchain and WWoof3." />
        <meta name="twitter:image" content="https://barkhaus.io/favicon/metaimage_barkhaus.png" />

        <meta name="description" content="https://barkhaus.io/favicon/metaimage_barkhaus.png" />
        <link rel="canonical" href="https://Barkhaus.io/" />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div onClick={bark} onMouseEnter={mouseEnter} onMouseOut={mouseLeave}>
        <Aurora />
        <Nav />
        <main className={`relative`} >
          {children}
          <BackToTopButton />
        </main>
        <Footer />
        <div 
            class={`pointer-events-none block h-12 w-12 fixed rounded-full dog-ball`} 
            style={{
                background : "url(/images/ball.png) center center / contain no-repeat", 
                top : mousePos.y + "px", 
                left : mousePos.x + "px",
                transform: "translate(-50%,-50%)",
                mixBlendMode: windowPath === "/pack/" ? "normal" : "multiply",
                isolation: "isolate",
                zIndex: 50000
            }} 
            ref={pointer}
        ></div>
      </div>
    </>
  )
}