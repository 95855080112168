import React, {useEffect, useRef, useState} from "react"
import BackToTop from "../../images/back-to-top.svg"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const BackToTopButton = () => {
  const path = typeof window !== "undefined" ? window.location.pathname : null;
  const triggerRef = useRef(null);
  const pinRef = useRef(null);
  const [showBTT, setShowBTT] = useState(false);

  useEffect(()=> {
    setShowBTT(showBTT)
  },[showBTT])

  useEffect(() => {
    let startAnim = ScrollTrigger.create({
      trigger: triggerRef.current,
      scrub: true,
      start: "bottom +=100%",
      end: "+=200%",
      onEnter: () => { setShowBTT(true) },
      onLeaveBack: () => { setShowBTT(false) }
    });

    let endAnim = ScrollTrigger.create({
      trigger: document.getElementById('footer'),
      scrub: true,
      start: 'bottom bottom',
      onEnter: () => { 
        let top = pinRef.current.getBoundingClientRect().top + window.scrollY;
        pinRef.current.style.position = 'absolute';
        pinRef.current.style.bottom = top;
      },
      onLeaveBack: () => { 
        pinRef.current.style.position = null;
        pinRef.current.style.bottom = null;
      }
    });

    return () => {
      setTimeout(()=>{
        startAnim.refresh();
        endAnim.refresh();
      }, 1000);
    };
  }, [path]);

  const scrollToTop = ()=> {
    window.scrollTo({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div
        ref={triggerRef}
        className={`
          back-to-top-trigger
          block
          right-0
          absolute
        `} 
        style={{ 
          top: 250 + 'vh',
          background: "red"
        }}
      ></div>
      <button
        onClick={scrollToTop}
        ref={pinRef}
        className={`
          back-to-top
          block
          h-14 w-14
          md:h-32 md:w-32
          rounded-full
          fixed
          right-5
          md:right-10
          z-30
          ${showBTT ? 'active' : ''}
        `}
      >
        <div className={"back-to-top-text absolute top-0 left-0 w-full h-auto"} src={BackToTop} alt="" style={{paintOrder: 'stroke'}}>
          <svg width="100%" viewBox="0 0 126 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M99.3688 20.5924L103.058 16.4253L101.434 14.9821L102.501 13.7771L107.141 17.8967L106.074 19.1016L104.45 17.6602L100.761 21.8274L99.3688 20.5924Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M65.4,1l3,0.2c0.4,0,0.8,0.1,1.1,0.3S70,2,70.1,2.3c0.1,0.3,0.2,0.6,0.1,0.9c0,0.4-0.2,0.7-0.4,0.9
              c-0.2,0.2-0.5,0.4-0.7,0.4l0,0.1c0.2,0,0.4,0.1,0.6,0.3s0.4,0.3,0.5,0.6c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.3,0.9
              c-0.2,0.3-0.4,0.5-0.8,0.7c-0.3,0.2-0.8,0.2-1.2,0.2L64.9,8L65.4,1z M67.8,7c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.3,0.4-0.7
              c0-0.3-0.1-0.6-0.3-0.7S68.2,5.2,68,5.2l-1.3-0.1l-0.1,1.8L67.8,7z M67.7,4.1c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.3,0.3-0.6
              c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.7-0.2l-1-0.1L66.7,4L67.7,4.1z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M76.6,8.1l-2.2-0.5L73.6,9l-1.5-0.3l4-6.3l1.4,0.3l0.9,7.4l-1.5-0.3L76.6,8.1z M76.5,6.8l-0.2-2.4l-0.1,0l-1.2,2L76.5,6.8z
              "/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M83.5,9.3l-0.8-0.3l-0.9,2.3l-1.4-0.6l2.5-6.5l2.5,1c0.5,0.2,0.9,0.5,1.2,0.8c0.3,0.3,0.4,0.7,0.5,1.1c0,0.4,0,0.8-0.1,1.2
              c-0.2,0.4-0.4,0.8-0.8,1s-0.8,0.4-1.2,0.4l0.4,3l-1.5-0.6L83.5,9.3z M83.2,7.9L84,8.2c0.3,0.1,0.6,0.2,0.9,0.1s0.5-0.2,0.6-0.6
              c0.1-0.3,0.1-0.6-0.1-0.8S85,6.5,84.6,6.4l-0.8-0.3L83.2,7.9z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M90.9,7.6l1.3,0.8l-1.3,2.4l0,0l3.4-1.3l1.5,0.9l-3.6,1.4l0.2,4.8l-1.5-0.9l-0.1-3.4l-1,0.3l-1.1,1.9l-1.3-0.8L90.9,7.6z"
              />
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M105.1,19.3l-1.4-1.2l-3.7,4.2l-1.2-1l3.7-4.2l-1.4-1.2l0.9-1l3.9,3.5L105.1,19.3z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M104,25.8c-0.1-0.6,0-1.1,0.3-1.7c0.3-0.5,0.6-1,1.1-1.4c0.5-0.4,1-0.7,1.6-0.9c0.6-0.2,1.2-0.2,1.7,0
              c0.6,0.2,1.1,0.5,1.5,1c0.4,0.5,0.7,1.1,0.8,1.6c0.1,0.6,0,1.1-0.3,1.7c-0.3,0.5-0.7,1-1.2,1.5c-0.5,0.4-1,0.7-1.6,0.9
              c-0.6,0.2-1.1,0.2-1.7,0s-1.1-0.5-1.5-1C104.3,26.9,104.1,26.3,104,25.8z M107.1,27c0.5-0.1,1-0.3,1.5-0.7c0.5-0.4,0.8-0.9,1-1.3
              c0.2-0.5,0.1-0.9-0.3-1.3c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0-1,0.1c-0.3,0.1-0.7,0.3-1,0.6c-0.5,0.4-0.8,0.8-0.9,1.3
              c-0.1,0.5-0.1,0.9,0.3,1.3C106.2,26.9,106.6,27.1,107.1,27z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M117.8,36.9l-0.9-1.6l-5,2.6l-0.7-1.4l5-2.6l-0.9-1.6l1.2-0.6l2.5,4.6L117.8,36.9z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M114.5,42.6c0.1-0.6,0.4-1.1,0.9-1.5s1-0.7,1.6-1c0.6-0.2,1.2-0.3,1.8-0.3s1.1,0.2,1.6,0.6c0.5,0.3,0.8,0.8,1.1,1.5
              c0.2,0.6,0.3,1.2,0.1,1.8c-0.1,0.6-0.4,1.1-0.9,1.5c-0.4,0.4-1,0.7-1.6,1c-0.6,0.2-1.2,0.3-1.8,0.3s-1.1-0.2-1.6-0.6
              c-0.5-0.3-0.8-0.8-1-1.5C114.4,43.8,114.4,43.1,114.5,42.6z M116.9,44.8c0.5,0.1,1,0.1,1.6-0.2c0.6-0.2,1-0.5,1.4-0.9
              c0.3-0.4,0.4-0.8,0.2-1.3c-0.1-0.3-0.3-0.6-0.6-0.7s-0.6-0.2-1-0.2c-0.4,0-0.7,0.1-1.1,0.2c-0.6,0.2-1,0.5-1.3,0.9
              c-0.3,0.4-0.4,0.8-0.2,1.3C116.1,44.4,116.4,44.7,116.9,44.8z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M123.7,52.4c-0.1,0.4-0.4,0.8-0.7,1s-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.1-1.2,0c-0.4-0.1-0.7-0.3-1-0.7
              c-0.3-0.3-0.5-0.8-0.6-1.3l-0.2-1.1l-2.1,0.4l-0.3-1.5l6.9-1.4l0.5,2.6C123.9,51.5,123.9,52,123.7,52.4z M120.7,52.3
              c0.2,0.2,0.5,0.3,0.9,0.2c0.4-0.1,0.6-0.3,0.8-0.5c0.1-0.3,0.1-0.6,0.1-0.9l-0.2-0.9l-2.1,0.4l0.2,0.9
              C120.3,51.8,120.5,52,120.7,52.3z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M125,65.3l-0.2,3c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.4,0-0.7-0.2-0.9-0.4
              c-0.2-0.2-0.4-0.5-0.4-0.7l-0.1,0c0,0.2-0.1,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-1-0.3
              s-0.5-0.4-0.7-0.8s-0.2-0.8-0.2-1.2l0.3-3.2L125,65.3z M119,67.7c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.3,0.3,0.7,0.4
              c0.3,0,0.6-0.1,0.7-0.3c0.2-0.2,0.2-0.4,0.3-0.7l0.1-1.3l-1.8-0.1L119,67.7z M121.9,67.6c0,0.3,0,0.5,0.1,0.7
              c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.2c0.1-0.2,0.2-0.4,0.2-0.7l0.1-1l-1.6-0.1L121.9,67.6z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M118,76.5l0.5-2.2l-1.5-0.8l0.3-1.5l6.3,4l-0.3,1.4l-7.4,0.9l0.3-1.5L118,76.5z M119.2,76.4l2.4-0.2l0-0.1l-2.1-1.2
              L119.2,76.4z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M116.7,83.3l0.3-0.8l-2.3-0.9l0.6-1.4l6.5,2.5l-0.9,2.5c-0.2,0.5-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.4-1.1,0.5s-0.8,0-1.2-0.1
              c-0.4-0.2-0.8-0.4-1-0.8c-0.3-0.3-0.4-0.8-0.4-1.2l-3,0.5l0.6-1.5L116.7,83.3z M118.2,83l-0.3,0.8c-0.1,0.3-0.2,0.6-0.1,0.9
              c0.1,0.3,0.2,0.5,0.6,0.6c0.3,0.1,0.6,0.1,0.8-0.1c0.2-0.2,0.4-0.4,0.5-0.8l0.3-0.8L118.2,83z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M118.5,90.7l-0.8,1.3l-2.4-1.3l0,0l1.3,3.4l-0.9,1.5l-1.4-3.6l-4.8,0.2l0.9-1.5l3.4-0.1l-0.3-1l-1.9-1.1l0.8-1.3
              L118.5,90.7z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M106.8,105l1.2-1.4l-4.2-3.7l1-1.2l4.2,3.7l1.2-1.4l1,0.9l-3.5,3.9L106.8,105z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M100.3,103.9c0.6-0.1,1.1,0,1.7,0.3s1,0.6,1.5,1.1c0.4,0.5,0.7,1,0.9,1.6c0.2,0.6,0.2,1.2,0,1.7s-0.5,1.1-1,1.5
              c-0.5,0.4-1.1,0.7-1.6,0.8c-0.6,0.1-1.1,0-1.7-0.3s-1-0.7-1.5-1.2c-0.4-0.5-0.7-1-0.9-1.6c-0.2-0.6-0.2-1.1,0-1.7s0.5-1.1,1-1.5
              C99.2,104.2,99.7,104,100.3,103.9z M99.1,107c0.1,0.5,0.3,1,0.7,1.5c0.4,0.5,0.9,0.8,1.3,0.9c0.5,0.2,0.9,0.1,1.3-0.3
              c0.3-0.2,0.4-0.5,0.5-0.8c0-0.3,0-0.6-0.1-1c-0.1-0.3-0.3-0.7-0.6-1c-0.4-0.5-0.8-0.8-1.3-0.9c-0.5-0.1-0.9-0.1-1.3,0.3
              C99.2,106.1,99,106.5,99.1,107z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M89.2,117.7l1.6-0.9l-2.7-5l1.4-0.7l2.7,5l1.6-0.9l0.6,1.2l-4.6,2.5L89.2,117.7z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M83.5,114.4c0.6,0.1,1.1,0.4,1.5,0.8s0.7,1,1,1.6c0.2,0.6,0.3,1.2,0.3,1.8c0,0.6-0.2,1.1-0.6,1.6c-0.3,0.5-0.8,0.8-1.5,1.1
              c-0.6,0.2-1.2,0.3-1.8,0.1c-0.6-0.1-1.1-0.4-1.5-0.9c-0.4-0.4-0.7-1-1-1.6s-0.3-1.2-0.3-1.8c0-0.6,0.2-1.1,0.6-1.6
              c0.3-0.5,0.8-0.8,1.5-1C82.4,114.4,83,114.3,83.5,114.4z M81.3,116.9c-0.1,0.5-0.1,1,0.2,1.6c0.2,0.6,0.5,1,0.9,1.4
              s0.8,0.4,1.3,0.2c0.3-0.1,0.6-0.3,0.7-0.6c0.2-0.3,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-1.1c-0.2-0.6-0.5-1-0.9-1.3
              c-0.4-0.3-0.8-0.4-1.3-0.2C81.7,116.1,81.4,116.4,81.3,116.9z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M73.7,123.7c-0.4-0.1-0.8-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.5-1.1c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.4,0.3-0.7,0.7-1
              c0.3-0.3,0.8-0.5,1.3-0.6l1.1-0.2l-0.4-2.1l1.5-0.3l1.4,6.9l-2.6,0.5C74.6,123.9,74.2,123.8,73.7,123.7z M73.9,120.6
              c-0.2,0.2-0.3,0.5-0.2,0.9c0.1,0.4,0.3,0.6,0.5,0.8c0.3,0.1,0.6,0.1,0.9,0.1l0.9-0.2l-0.4-2.1l-0.9,0.2
              C74.4,120.3,74.1,120.4,73.9,120.6z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M60.9,125l-3-0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.4-0.6-0.7s-0.2-0.6-0.2-0.9c0-0.4,0.2-0.7,0.4-0.9
              s0.5-0.4,0.7-0.4l0-0.1c-0.2,0-0.4-0.1-0.6-0.3s-0.4-0.3-0.5-0.6c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.3-1
              c0.2-0.3,0.4-0.5,0.8-0.7s0.8-0.2,1.2-0.2l3.2,0.2L60.9,125z M58.4,119c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.3-0.4,0.7
              c0,0.3,0.1,0.6,0.3,0.7s0.4,0.2,0.7,0.3l1.3,0.1l0.1-1.8L58.4,119z M58.6,121.9c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.3-0.3,0.6
              c0,0.3,0.1,0.5,0.2,0.6c0.2,0.1,0.4,0.2,0.7,0.2l1,0.1l0.1-1.6L58.6,121.9z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M49.6,118l2.2,0.5l0.8-1.5l1.5,0.3l-3.9,6.3l-1.4-0.3l-0.9-7.4l1.5,0.3L49.6,118z M49.7,119.2l0.2,2.4l0.1,0l1.2-2.1
              L49.7,119.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M42.8,116.7l0.8,0.3l0.9-2.3l1.4,0.5l-2.5,6.5l-2.5-0.9c-0.5-0.2-0.9-0.5-1.2-0.8c-0.3-0.3-0.4-0.7-0.5-1.1s0-0.8,0.1-1.2
              c0.2-0.4,0.4-0.8,0.8-1c0.3-0.3,0.7-0.4,1.2-0.4l-0.5-3l1.5,0.6L42.8,116.7z M43.1,118.2l-0.8-0.3c-0.3-0.1-0.6-0.2-0.9-0.1
              c-0.3,0.1-0.5,0.2-0.6,0.6c-0.1,0.3-0.1,0.6,0.1,0.8c0.2,0.2,0.4,0.4,0.8,0.5l0.8,0.3L43.1,118.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M35.4,118.5l-1.3-0.7l1.3-2.4l0,0l-3.4,1.3l-1.5-0.9l3.6-1.4l-0.2-4.8l1.5,0.8l0.1,3.4l1-0.3l1.1-1.9l1.3,0.7L35.4,118.5z"
              />
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M21.1,106.9l1.4,1.2l3.7-4.2l1.2,1l-3.7,4.2l1.4,1.2l-0.9,1l-3.9-3.4L21.1,106.9z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M22.2,100.4c0.1,0.6,0,1.1-0.3,1.7c-0.3,0.5-0.6,1-1.1,1.5c-0.5,0.4-1,0.7-1.6,0.9c-0.6,0.2-1.2,0.2-1.7,0
              c-0.6-0.2-1.1-0.5-1.5-1c-0.4-0.5-0.7-1.1-0.8-1.6c-0.1-0.6,0-1.1,0.3-1.7c0.3-0.5,0.6-1,1.2-1.5s1-0.7,1.6-0.9s1.1-0.2,1.7,0
              c0.6,0.2,1.1,0.5,1.5,1C21.8,99.3,22.1,99.8,22.2,100.4z M19.1,99.2c-0.5,0.1-1,0.3-1.5,0.7c-0.5,0.4-0.8,0.9-0.9,1.3
              c-0.2,0.5-0.1,0.9,0.3,1.3c0.2,0.3,0.5,0.4,0.8,0.5c0.3,0,0.6,0,1-0.1c0.3-0.1,0.7-0.3,1-0.6c0.5-0.4,0.8-0.8,0.9-1.3
              c0.1-0.5,0.1-0.9-0.3-1.3C20,99.3,19.6,99.1,19.1,99.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M8.4,89.4L9.2,91l5-2.7l0.7,1.4l-5,2.7l0.9,1.6l-1.2,0.6L7.2,90L8.4,89.4z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M11.6,83.7c-0.1,0.6-0.4,1.1-0.8,1.5s-1,0.7-1.6,1c-0.6,0.2-1.2,0.3-1.8,0.3c-0.6,0-1.1-0.2-1.6-0.6
              c-0.5-0.3-0.8-0.8-1.1-1.5c-0.2-0.6-0.3-1.2-0.1-1.8S5,81.4,5.4,81s1-0.7,1.6-1c0.6-0.2,1.2-0.3,1.8-0.3c0.6,0,1.1,0.2,1.6,0.6
              c0.5,0.3,0.8,0.8,1,1.5C11.7,82.5,11.7,83.1,11.6,83.7z M9.2,81.5c-0.5-0.1-1-0.1-1.6,0.2c-0.6,0.2-1,0.5-1.4,0.9
              c-0.3,0.4-0.4,0.8-0.2,1.3c0.1,0.3,0.3,0.6,0.6,0.7c0.3,0.2,0.6,0.2,1,0.2c0.4,0,0.7-0.1,1.1-0.2c0.6-0.2,1-0.5,1.3-0.9
              c0.3-0.4,0.4-0.8,0.2-1.3C10,81.8,9.6,81.6,9.2,81.5z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M2.3,73.9c0.1-0.4,0.4-0.8,0.7-1c0.3-0.3,0.7-0.4,1.1-0.5c0.4-0.1,0.8-0.1,1.2,0c0.4,0.1,0.7,0.3,1,0.7
              c0.3,0.3,0.5,0.8,0.6,1.3l0.2,1.1L9.4,75l0.3,1.5l-6.9,1.4l-0.5-2.6C2.2,74.8,2.2,74.3,2.3,73.9z M5.4,74c-0.2-0.2-0.5-0.3-0.9-0.2
              s-0.6,0.3-0.7,0.5s-0.1,0.6-0.1,0.9l0.2,0.9L6,75.7l-0.2-0.9C5.7,74.5,5.6,74.2,5.4,74z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M1,61l0.2-3c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.6-0.2,0.9-0.2c0.4,0,0.7,0.2,0.9,0.4
              s0.4,0.5,0.4,0.7l0.1,0c0-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.6-0.5S6,56,6.4,56c0.3,0,0.6,0.1,1,0.3c0.3,0.2,0.5,0.4,0.7,0.8
              s0.2,0.8,0.2,1.2L8,61.5L1,61z M4,58.7c0-0.3,0-0.5-0.1-0.7s-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7l-0.1,1
              L4,59.7L4,58.7z M7,58.6c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.7-0.4c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.3,0.7l-0.1,1.3
              l1.8,0.1L7,58.6z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M8,49.7l-0.5,2.2L9,52.7l-0.3,1.5l-6.3-3.9l0.3-1.4L10,48l-0.3,1.5L8,49.7z M6.7,49.8l-2.4,0.2l0,0.1l2.1,1.2L6.7,49.8z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M9.2,42.9l-0.3,0.8l2.3,0.9L10.7,46l-6.6-2.5L5.1,41c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.7-0.5,1.1-0.5
              c0.4-0.1,0.8,0,1.2,0.1c0.4,0.2,0.8,0.4,1,0.8c0.3,0.3,0.4,0.7,0.4,1.2l3-0.5L12,42.5L9.2,42.9z M7.7,43.2L8,42.4
              c0.1-0.3,0.2-0.6,0.1-0.9c-0.1-0.3-0.2-0.5-0.6-0.6C7.3,40.8,7,40.8,6.8,41c-0.2,0.2-0.4,0.4-0.5,0.8l-0.3,0.8L7.7,43.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M7.4,35.5l0.7-1.3l2.4,1.3l0,0l-1.3-3.4l0.9-1.5l1.4,3.6l4.8-0.2l-0.8,1.5L12,35.5l0.3,1l1.9,1.1l-0.7,1.3L7.4,35.5z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M19,21.2l-1.2,1.4l4.3,3.7l-1,1.2l-4.3-3.7l-1.2,1.4l-1-0.9l3.4-3.9L19,21.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M25.5,22.3c-0.6,0.1-1.1,0-1.7-0.3c-0.5-0.3-1-0.6-1.5-1.1c-0.4-0.5-0.7-1-0.9-1.6c-0.2-0.6-0.2-1.2,0-1.7
              c0.2-0.6,0.5-1.1,1-1.5c0.5-0.4,1.1-0.7,1.6-0.8c0.6-0.1,1.1,0,1.7,0.3c0.5,0.3,1,0.6,1.5,1.2c0.4,0.5,0.7,1,0.9,1.6
              c0.2,0.6,0.2,1.1,0,1.7c-0.2,0.6-0.5,1.1-1,1.5C26.6,21.9,26.1,22.2,25.5,22.3z M26.7,19.2c-0.1-0.5-0.3-1-0.7-1.5
              c-0.4-0.5-0.9-0.8-1.3-0.9c-0.5-0.2-0.9-0.1-1.3,0.3c-0.3,0.2-0.4,0.5-0.5,0.8s0,0.6,0.1,1c0.1,0.3,0.3,0.7,0.6,1
              c0.4,0.5,0.8,0.8,1.3,0.9c0.5,0.1,0.9,0.1,1.3-0.3C26.6,20.1,26.8,19.7,26.7,19.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M36.5,8.4l-1.6,0.9l2.7,5L36.2,15l-2.7-5l-1.6,0.9l-0.6-1.2l4.6-2.5L36.5,8.4z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M42.2,11.7c-0.6-0.1-1.1-0.4-1.5-0.8c-0.4-0.4-0.7-0.9-1-1.6c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.6,0.2-1.1,0.6-1.6
              s0.8-0.8,1.5-1.1c0.6-0.2,1.2-0.3,1.8-0.1s1.1,0.4,1.5,0.9s0.7,1,1,1.6c0.2,0.6,0.3,1.2,0.3,1.8c0,0.6-0.2,1.1-0.6,1.6
              c-0.3,0.5-0.8,0.8-1.5,1C43.4,11.7,42.8,11.8,42.2,11.7z M44.4,9.2c0.1-0.5,0.1-1-0.2-1.6c-0.2-0.6-0.5-1-0.9-1.4S42.5,5.8,42,6
              c-0.3,0.1-0.6,0.3-0.7,0.6c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.7,0.2,1.1c0.2,0.6,0.5,1,0.9,1.3c0.4,0.3,0.8,0.4,1.3,0.2
              C44,10,44.3,9.7,44.4,9.2z"/>
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M52,2.3c0.4,0.1,0.8,0.4,1,0.7c0.3,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.8,0,1.2c-0.1,0.4-0.3,0.7-0.7,1
              c-0.3,0.3-0.8,0.5-1.3,0.6l-1.1,0.2l0.4,2.1l-1.5,0.3l-1.4-6.9l2.6-0.5C51.1,2.2,51.6,2.2,52,2.3z M51.9,5.4
              c0.2-0.2,0.3-0.5,0.2-0.9c-0.1-0.4-0.3-0.6-0.5-0.7s-0.6-0.1-0.9,0l-0.9,0.2L50.2,6L51,5.8C51.4,5.8,51.6,5.6,51.9,5.4z"/>
          </svg>
        </div>
        <div className={"back-to-top-arrow absolute top-0 left-0 w-full h-auto"} src={BackToTop} alt="">
          <svg width="100%" viewBox="0 0 126 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside" d="M43.9725 63.2497L47.46 66.6474L61.2866 53.2014V82.5271H66.2335V53.2014L80.0354 66.6715L83.5476 63.2497L63.7601 43.9724L43.9725 63.2497Z" fill="black" stroke="#ffffff" strokeWidth="0px" stroke-position="outside"/>
          </svg>
        </div>
        <span className={'sr-only'}>Back to the top of the page.</span>
      </button>
    </>
  )
}


export default BackToTopButton
