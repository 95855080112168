import * as React from "react"
import image from "../../images/aurora.png"
import * as Orb01 from "./Aurora.orb01.module.scss"
import * as Orb02 from "./Aurora.orb02.module.scss"

const Aurora = () => {
  return (
    <div id={'aurora'} className={"fixed block min-h-full min-w-full pointer-events-none z-0"}>
      <div className={Orb01.orb}>
        <div className={Orb01.xaxis}>
          <div className={Orb01.yaxis}>
            <div className={Orb01.scaleX}>
              <div className={Orb01.scaleY}>
                <div className={Orb01.opacity}>
                  <div className={Orb01.rotation}>
                    <img src={image} alt="" className={'orb'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Orb02.orb}>
        <div className={Orb02.xaxis}>
          <div className={Orb02.yaxis}>
            <div className={Orb02.scaleX}>
              <div className={Orb02.scaleY}>
                <div className={Orb02.opacity}>
                  <div className={Orb02.rotation}>
                    <img src={image} alt="" className={'orb'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aurora
